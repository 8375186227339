// assets
import { IconCoin, IconCash, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconCoin,
    IconCash,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const sales = {
    id: 'sales',
    title: 'Sales',
    type: 'group',
    children: [
        {
            id: 'sale',
            title: 'Sales',
            type: 'item',
            url: '/sales',
            icon: icons.IconCoin,
            breadcrumbs: false
        }
    ]
};

export default sales;
