// assets
import { IconAdjustments, IconHelp } from '@tabler/icons';

// constant
const icons = { IconAdjustments, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.IconAdjustments,
            breadcrumbs: false
        },
        {
            id: 'help',
            title: 'Help',
            type: 'item',
            url: '/help',
            icon: icons.IconHelp,
            external: true,
            target: true
        }
    ]
};

export default other;
