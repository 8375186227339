import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import database from './database';
import sales from './sale';
import admin from './admin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [admin]
};

export default menuItems;
