import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const SamplePage = Loadable(lazy(() => import('views/profile')));




// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/admin',
    element: <MainLayout />,
    children: [
        {
            path: '/admin/fonctionnaire',
            element: <SamplePage />
        }
        
    ]
};

export default AdminRoutes;
