import { downloadFile, findById, findCurrent, uploadFile } from "../services/fonctionnaireService";
import { create } from "../services/fonctionnaireService";
import { update } from "../services/fonctionnaireService";
import { search } from "../services/fonctionnaireService";
import { call, put } from "redux-saga/effects";
import { createModule } from "saga-slice";

const fonctionnaireSlice = createModule({
    name: "fonctionnaire",
    initialState: {
        list: [],
        current:null,
        total: 0,
        isFetching: false,
        error: null,
    },
    reducers: {
        create: (state) => {
            state.isFetching = true;
        },
        update: (state) => {
            state.isFetching = true;
        },
        findOne: (state) => {
            state.isFetching = true;
        },
        findCurrent: (state) => {
            state.isFetching = true;
        },
        search: (state) => {
            state.isFetching = true;
        },
        uploadFile: (state) => {
            state.isFetching = true;
        },
        downloadFile: (state) => {
            state.isFetching = true;
        },
        fetchSuccess: (state, payload) => {
            state.list = payload.body;
        },
        fetchCurrentSuccess: (state, payload) => {
            state.current = payload.body;
        },
        finishFetching: (state) => {
            state.isFetching = false;
        },
        fetchError: (state) => {
            state.error = "An error occured";
        },
    },
    sagas: (A) => ({
        *[A.search]({ payload }) {
            try {
                const { data } = yield search(payload);
                yield put(A.finishFetching());
                yield put(A.fetchSuccess(data));
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },

        *[A.update]({ payload }) {
            try {
                const { data } = yield update(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },

        *[A.create]({ payload }) {
            try {
                const { data } = yield create(payload.data);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },

        *[A.findOne]({ payload }) {
            try {
                const { data } = yield findById(payload);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.findCurrent]({ payload }) {
            try {
                const { data } = yield findCurrent();
                yield put(A.finishFetching());
                yield put(A.fetchCurrentSuccess(data));
                yield call(payload.onSuccess, data.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.uploadFile]({ payload }) {
            try {
                const { data } = yield uploadFile(payload.formData);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data?.body);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
        *[A.downloadFile]({ payload }) {
            try {
                const { data } = yield downloadFile(payload.guid);
                yield put(A.finishFetching());
                yield call(payload.onSuccess, data);
            } catch (e) {
                console.log(e);
                yield put(A.finishFetching());
                yield put(A.fetchError());
            }
        },
    })
})

export default fonctionnaireSlice;
export const fonctActions = fonctionnaireSlice.actions;