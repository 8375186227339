import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
const ProfilePage = Loadable(lazy(() => import('views/profile')));



// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/main',
    element: <MainLayout />,
    children: [
        {
            path: '/main/profile',
            element: <ProfilePage />
        }
        
    ]
};

export default MainRoutes;
