import axios from "axios";
import config from "../config";
import React from "react";
import { useNavigate } from "react-router-dom";

const instance = axios.create({
  baseURL: config.baseURL,
});

instance.interceptors.request.use(
  async config => {
    if(sessionStorage.getItem("token")){
      config.headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
        //Accept:"application/json",
        "Content-Type": "application/json",
      }
    }else{
      config.headers = {
        //Authorization: "Bearer " + sessionStorage.getItem("token"),
        //Accept:"application/json",
        "Content-Type": "application/json",
      }
    }
    
    return config;
  },
  error => {
    Promise.reject(error)
  });

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error)
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      // originalRequest._retry = true;

       try {
      //   const refreshToken = localStorage.getItem('refreshToken');
      //   const response = await axios.post('/api/refresh-token', { refreshToken });
      //   const { token } = response.data;

      //   localStorage.setItem('token', token);

      //   // Retry the original request with the new token
      //   originalRequest.headers.Authorization = `Bearer ${token}`;
         return axios(originalRequest);
      
      } catch (error) {
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
