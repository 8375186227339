// assets
import { IconUserSearch, IconTruck, IconUsers, IconBox, IconGrowth, IconLeaf } from '@tabler/icons';

// constant
const icons = {
    IconBox,
    IconGrowth,
    IconLeaf,
    IconUsers,
    IconTruck,
    IconUserSearch
};

const database = {
    id: 'database',
    title: 'Database',
    caption: 'Database management',
    type: 'group',
    children: [
        {
            id: 'category',
            title: 'Category',
            type: 'item',
            url: '/base/category/',
            icon: icons.IconGrowth,
            breadcrumbs: false
        },
        {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: '/base/product/',
            icon: icons.IconBox,
            breadcrumbs: false
        },
        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/base/customers/list',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'suppliers',
            title: 'Suppliers',
            type: 'item',
            url: '/base/suppliers/list',
            icon: icons.IconTruck,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/base/users/list',
            icon: icons.IconTruck,
            breadcrumbs: false
        },
    ]
};

export default database;
