import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Backdrop, CircularProgress, CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import "./i18n";

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { useState } from 'react';
import { dataActions } from 'sagas/dataSlice';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(dataActions.loadRefData());
    },[dispatch])
    

    return (
        <StyledEngineProvider injectFirst>
            {isLoading ? <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                  open={true}>
                                  
                                  <CircularProgress color="inherit" />
                                </Backdrop> :
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>}
        </StyledEngineProvider>
    );
};

export default App;
